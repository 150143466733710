import { InputGroup, Pill } from '@workos-inc/component-library';
import React from 'react';
import { ArrowRight } from 'react-feather';
import { AttributeContainer } from './attribute-container';

export interface MapAttributeProps<T extends string> {
  attributeId: T;
  disabled: boolean;
  isRequired?: boolean;
  name: string;
  onChange: (data: { attributeId: T; value: string }) => void;
  showValidationErrors: boolean;
  value: string;
}

export const MapAttribute = <T extends string>({
  attributeId,
  name,
  disabled,
  isRequired = true,
  onChange,
  showValidationErrors,
  value,
}: Readonly<MapAttributeProps<T>>): JSX.Element => {
  const inputId = `map-custom-attribute__${attributeId}`;
  const hasError = showValidationErrors && isRequired && !value;

  return (
    <AttributeContainer>
      <InputGroup
        inline
        className="w-full"
        error={hasError ? ' ' : undefined}
        id={inputId}
        name={inputId}
        onChange={(event) =>
          onChange({
            attributeId,
            value: event.target.value,
          })
        }
        placeholder={`attribute_name${isRequired ? ' (required)' : ''}`}
        readOnly={disabled}
        testId={inputId}
        value={value}
      />

      <ArrowRight className="mx-4 min-w-[16px]" size={16} />

      <Pill className="w-full">{name}</Pill>
    </AttributeContainer>
  );
};
